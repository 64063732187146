%dot {
  content: " • ";
  white-space: pre;
}

%dot-no-space {
  content: "•";
}

.icon-dot {
  &::before {
    @extend %dot;
  }
}