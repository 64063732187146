@import '/node_modules/vue-multiselect/dist/vue-multiselect.min.css';

$vms-border-radius: $border-radius-small;
$vms-input-padding-x: 16px;
$vms-input-padding-y: 11px;

.multiselect__placeholder {
  padding-top: 0;
  color: $grey-light;
}

.multiselect__placeholder,
.multiselect__input {
  padding: 0;
  margin: 0 0 $vms-input-padding-y;
  line-height: 24px; // just as high as tags
}

.multiselect__input {
  color: $black;
}

.multiselect__select {
  display: none;
}

.multiselect__tags {
  cursor: text;
  border: none;
  padding: $vms-input-padding-y 40px 0 $vms-input-padding-x;
  border-radius: $vms-border-radius;
  // height: 46px;
  min-height: auto;
}

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.multiselect__tag {
  margin-bottom: $vms-input-padding-y;
  padding: 6px 30px 7px 14px;
  border-radius: $border-radius;
  background: $green;
  font-weight: $fw-bold;
  font-size: $fs-mini;
  @extend %letter-spaced;
  overflow: hidden;
}

.multiselect__tag-icon {
  margin-left: 6px;
  padding: 0 2px;
  box-sizing: content-box;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: background-color 0.1s ease;

  &::after {
    transition: color 0.1s ease;
  }
}

.multiselect__input {
  font-size: $fs-normal;
}

.multiselect__content-wrapper {
  border-bottom-left-radius: $vms-border-radius;
  border-bottom-right-radius: $vms-border-radius;
}

.multiselect__option {
  padding: 8px $vms-input-padding-x;
  min-height: auto;
  font-weight: $fw-bold;
  font-size: $fs-mini;
  text-transform: uppercase;
  @extend %letter-spaced;

  &::after {
    content: none !important;
    background: $green;
  }
}

.multiselect__option--selected {
  background: $grey-lightest;
}

.multiselect__option--highlight {
  background: $green;
}

.multiselect__spinner:after,.multiselect__spinner::before {
  border-top-color: $green;
}