.topbar-collection {
  --foldout-color: #{$grey-middle};
  flex: 0 0 auto;
  display: flex;
  position: relative;

  .collection-contents {
    position: absolute;
    top: calc(100% + #{$topbar-padding-y});
    right: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 1200px;
    max-width: calc(100vw - 2 * #{$app-padding-x});
    max-height: calc(100vh - #{$topbar-height} - #{$gutter});
    padding: 0;
    z-index: 200;
    background-color: var(--foldout-color);
    border-radius: $border-radius-mini;
    @include box-shadow;
    transition: opacity $s-normal ease,
                visibility $s-normal ease;

    &:not(.visible) {
      visibility: hidden;
      opacity: 0;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
    }

    // Triangle
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      right: 230px/2 - 10px; // Collection button width / 2 - 10px (border-width x 2 / 2)
      width: 0;
      height: 0;
      opacity: 1;
      border: 10px solid transparent;
      border-bottom-color: var(--foldout-color);
      pointer-events: none;
      z-index: 10;
    }

    &-inner {
      display: grid;
      overflow-y: auto;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      gap: $gutter;
      padding: $gutter $gutter 0;

      // This makes sure you can scroll into the "padding bottom"
      &::after {
        content: "";
        display: block;
        grid-column: 1 / -1;
        margin-top: #{-1 * $gutter};
        height: $gutter;
      }

      // Stack vertically if screen gets too narrow
      @media screen and (max-width: 1080px) {
        grid-template-columns: 1fr;
      }
    }

    h3 {
      &:not(:first-child) {
        margin-top: 2em;
      }
    }

    > .left,
    > .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .collection-artists,
    .collection-tracks {
      width: 100%;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        div {
          width: 100%;
        }
      }
    }

    .buttons-wrapper {
      margin-top: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      > * {
        flex: 0 0 auto;
      }

      button + button {
        margin-left: 10px;
      }
    }

    .error {
      width: 100%;
      flex: 0 0 auto;
      margin: 1.35em 0;
      font-weight: $fw-black;
    }

    .audio-feature-ranges-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      align-items: flex-start;
      gap: 15px $gutter-small;
      margin-bottom: $gutter-small;

      .audio-feature-range {
        width: 100%;
        grid-column: auto / span 1;
      }

      .audio-feature-type {
        font-size: $fs-mini;
        margin: 0 0 5px;
      }
    }
  }
}