.track-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 $gutter;

  .left {
    flex: 0 0 auto;
    width: $left-width;

    .cover {
      display: block;
      width: 200px;
      height: 200px;
      object-fit: contain;
      margin-left: auto;
      margin-right: $gutter;
    }
  }

  .right {
    flex: 1 1 auto;

    .track-container-type {
      display: block;
      font-size: $fs-mini;
      margin-bottom: -1em;
      font-weight: $fw-bold;
      @extend %letter-spaced;
    }

    .title {
      margin-top: 0;
      margin-bottom: 0;
    }

    .subtitle {
      margin-top: 0;
      color: $grey-lightest;
    }
  }
}