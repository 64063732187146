$loading-spinner-duration: 1s;

.loading-spinner {
  align-self: center;
  display: block;
  position: relative;
  height: var(--spinner-size);
  width: var(--spinner-size);
  border: var(--spinner-border-width) solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spin $loading-spinner-duration cubic-bezier(.44,.15,.56,.86) infinite;

  &.large {
    --spinner-size: 120px;
    --spinner-border-width: 3px;
    margin: 100px 0 0;
  }

  &.small {
    --spinner-size: 25px;
    --spinner-border-width: 2px;
    margin: calc(var(--spinner-size) / 2) 0;
  }
}