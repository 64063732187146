.spotify-track {
  --cover-art-size: 50px;

  &:last-child {
    td:first-of-type {
      border-bottom-left-radius: $border-radius-small;
    }

    td:last-of-type {
      border-bottom-right-radius: $border-radius-small;
    }
  }

  &.full {
    .left {
      position: sticky;
      left: 0;
      width: $left-width + $app-padding-x;
      padding: $gutter/2 $gutter $gutter/2 $app-padding-x;
      background-color: $black;
      z-index: 150;

      &.clickable {
        .icon-play,
        .icon-pause {
          left: calc(#{$app-padding-x} / 2 - var(--icon-playpause-size) / 2);
        }
      }
    }

    &.placeholder {
      padding: $gutter/2 $gutter $gutter/2 $app-padding-x;
    }
  }

  &.teaser {
    & + .spotify-track.teaser {
      margin-top: 4px;
    }

    .left {
      $track-teaser-padding: 7px;
      position: relative;
      width: 100%;
      background-color: $grey-darker;
      padding: $track-teaser-padding;
      padding-right: $gutter/3;
      border-radius: 5px;

      &.clickable {
        // Play button
        .icon-play,
        .icon-pause {
          left: calc(var(--cover-art-size) / 2 - var(--icon-playpause-size) / 2 + #{$track-teaser-padding});
        }

        &:not(.playing-preview):hover {
          .icon-play,
          .icon-pause {
            opacity: 1;
          }
        }
      }
    }

    &.placeholder {
      .loading-spinner {
        margin-left: calc(var(--cover-art-size) / 2 - var(--spinner-size) / 2);
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: stretch;
    text-align: left;
    user-select: auto; // makes sure people can copy track name + artists more easily

    .cover {
      flex: 0 0 auto;
      display: block;
      width: var(--cover-art-size);
      height: var(--cover-art-size);
      object-fit: contain;
    }

    .song {
      flex: 1 1 auto;
      padding: 0 20px;

      .title,
      .artists {
        margin: 0;
        font-size: $fs-normal;
      }

      .artists {
        margin-top: 0.25em;
        font-weight: $fw-light;
      }
    }

    .icon-plus {
      flex: 0 0 auto;
      --icon-plus-size: #{$fs-normal + 4px};
      --icon-plus-padding: 4px;
    }

    .icon-copy-link {
      flex: 0 0 auto;
      margin-left: $gutter-mini;
      --icon-copy-link-size: #{$fs-normal + 4px};
      --icon-copy-link-padding: 4px;
    }

    &.clickable {
      transition: opacity $s-normal ease,
                  background-color $s-normal ease;

      // Play button
      .icon-play,
      .icon-pause {
        display: block;
        position: absolute;
        top: calc(50% - var(--icon-playpause-size) / 2);
        opacity: 0;
        transition: opacity $s-normal ease;
      }

      &.playing-preview {
        background-color: $green;

        .icon-play,
        .icon-pause {
          opacity: 1;
        }

        .icon-plus.added {
          &::before,
          &::after {
            background-color: $white;
          }
        }
      }

      &:not(.playing-preview):hover {
        .icon-play,
        .icon-pause {
          opacity: 0.3;
        }
      }
    }
  }

  td {
    background-color: $grey-middle;
    padding: 0 15px;
    text-align: center;

    &:hover {
      background-color: lighten($grey-middle, 1);
    }
  }
}