.audio-features-metrics {
  margin-top: $gutter;
  display: grid;
  grid-template-columns: auto 1fr;

  h2 {
    grid-column: 1 / -1;
  }

  .audio-feature-metric {
    display: contents;
    line-height: 2.5;

    .audio-feature-type {
      --space-between: 15px;
      display: flex;
      padding-right: calc(var(--space-between) * 2);
      justify-content: stretch;
      align-items: center;

      &::after {
        content: "        "; // line length.. haha
        white-space: pre;
        display: inline-block;
        flex: 1 1 auto;
        height: 1px;
        background-color: $grey-light;
        transform: translateX(var(--space-between));
      }
    }
  }
}