.icon-copy-link {
  --icon-copy-link-size: #{$fs-normal};
  --icon-copy-link-padding: 0px;
  display: inline-block;
  min-width: calc(var(--icon-copy-link-size) + var(--icon-copy-link-padding));
  height: calc(var(--icon-copy-link-size) + var(--icon-copy-link-padding));
  position: relative;

  &.in-body-text {
    --icon-copy-link-size: 1em;
    vertical-align: middle;
    margin: 0 0.5ch;
    top: -1px;
  }

  &.animated {
    animation: pop $s-slow ease-out,
               green-text $s-slow ease-out;
  }

  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 2 * var(--icon-copy-link-padding));
    height: calc(100% - 2 * var(--icon-copy-link-padding));
    transform: translate(-50%, -50%);
    transition: filter $s-normal ease;

    * {
      fill: currentColor;
    }
  }

  &.clickable:hover svg {
    @include glow;
  }

  button & {
    --icon-copy-link-size: 1em;
    vertical-align: top; // makes sure icon doesn't push itself down and make button higher

    &:first-child {
      margin-right: 1ch;
    }
  }
}