// COLORS
$green: #1DB954;
$green-dark: #179744;
$white: #FFFFFF;
$black: #141414;
$grey-darker: #181818;
$grey-dark: #1f1f1f;
$grey-middle: #222326;
$grey-light: #494949;
$grey-lighter: #aeb1b6;
$grey-lightest: #c4c7ca;

// FONT-SIZES
$fs-mini: 11px;
$fs-small: 12px;
$fs-normal: 14px;
$fs-medium: 18px;
$fs-large: 24px;
$fs-larger: 36px;
$fs-largest: 54px;

$fw-light: 300;
$fw-normal: 400;
$fw-semibold: 500;
$fw-bold: 700;
$fw-black: 900;

// BORDER-RADII
$border-radius: 500px;
$border-radius-small: 23px;
$border-radius-mini: 6px;

// DISTANCES
$app-padding-x: 75px;
$app-padding-y: 50px;

$left-width: 350px;
$gutter: 50px;
$gutter-small: 30px;
$gutter-mini: 10px;

$button-padding-y: 14px;
$button-padding-x: 44px;

$topbar-padding-y: 15px;
$topbar-height: 93px; // TODO: HARDCODED FOR NOW, SET CSS VAR WITH JS INSTEAD

// SPEEDS
$s-normal: 0.3s;
$s-slow: 0.7s;

@mixin glow {
  filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 10px #fff);
}

@mixin box-shadow {
  box-shadow: 0 0 30px rgba(#000, 0.3);
}