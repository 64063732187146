[data-modal="share-collection"] {
  .copy-share-link {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;

    button {
      padding-left: $gutter-small - 5px;
      padding-right: $gutter-small;
      min-width: auto;
    }
  }
}