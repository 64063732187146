.help-section-button {
  position: fixed;
  right: $app-padding-x;
  bottom: $app-padding-y;
  z-index: 150;

  > button {
    svg {
      width: 1.25em;
      height: 1.25em;

      * {
        fill: currentColor;
      }
    }
  }
}