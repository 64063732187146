#app {
  position: relative;
  padding: $app-padding-y/2 $app-padding-x 0;
  z-index: 999;

  &.drag-over {
    &::before {
      content: "Drop it like it's hot";
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: $white;
      font-weight: $fw-bold;
      font-size: $fs-large;
      z-index: 1000;
    }
  }
}

.topbar {
  flex: 0 0 auto;
  width: calc(100% + #{$app-padding-x * 2});
  margin: 0 #{-1 * $app-padding-x};
  padding: $app-padding-y/2 $app-padding-x $topbar-padding-y;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 300;
  background-color: $black;

  .topbar-collection {
    margin-left: $gutter-mini;
  }
}

.results {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0;
  padding: $gutter 0 $gutter*3; // large padding-bottom so help section button can never get in the way of the page content

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .warning {
    margin: 3em 0 2.5em;

    + .warning {
      margin-top: -2em;
    }
  }
}

.subscript {
  margin-top: auto;
  margin-bottom: 40px;
}