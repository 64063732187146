$modal-overlay-backdrop-blur: 3px;
$modal-h3-margin-top: 1.75em;

.v--modal-overlay {
  background-color: rgba($black, 0.6) !important;

  @supports (backdrop-filter: blur($modal-overlay-backdrop-blur)) {
    backdrop-filter: blur($modal-overlay-backdrop-blur) brightness(85%);
  }

  .v--modal-background-click {
    cursor: pointer;
  }

  .v--modal {
    padding: $gutter*1.35 $gutter $gutter;
    max-width: calc(100vw - 2 * 75px) !important;
    left: 50% !important;
    transform: translateX(-50%);
    background-color: $grey-middle;
    border-radius: $border-radius-mini;
    font-weight: $fw-normal;
    font-size: $fs-medium;
    line-height: 1.8;
    cursor: auto;
    @include box-shadow;

    h3,
    .audio-feature-type-explanation {
      &:not(:first-child) {
        margin-top: $modal-h3-margin-top;
      }
    }

    h3 {
      margin-bottom: 0;

      + p {
        margin-top: 0;
      }
    }

    h2 {
      margin-bottom: 0.5em;

      + * {
        margin-top: 0 !important;
      }
    }

    details {
      margin: $gutter-mini 0;

      &[open] {
        summary {
          text-decoration-color: currentColor;
        }
      }

      summary {
        color: $green;
        text-decoration: underline;
        text-decoration-color: transparent;
        text-underline-offset: 0.45em;
        transition: text-decoration-color $s-normal ease-out;

        + * {
          margin-top: $gutter-small;
        }
      }

      > *:last-child {
        margin-bottom: $gutter;
      }
    }

    ul {
      li {
        position: relative;
        padding-left: $gutter-mini * 2;

        &::before {
          @extend %dot-no-space;
          position: absolute;
          left: 0;
          top: 0;
          height: 1em;
          transform: scale(0.75);
          transform-origin: center center;
        }

        + li {
          margin-top: 1em;
        }
      }
    }

    .modal-buttons {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: $gutter;
    }
  }
}

@supports not (backdrop-filter: blur($modal-overlay-backdrop-blur)) {
  .v--modal-block-scroll {
    #app > *:not(#modals-container) {
      // NOTE: Don't transition this filter, will cause performance issues!
      filter: blur($modal-overlay-backdrop-blur);
    }
  }
}

// Used by the modal backdrop (overlay)
.overlay-fade-enter-active, .overlay-fade-leave-active,
// Used by the modal itself
.v-enter-active, .v-leave-active {
  transition: opacity $s-normal ease !important;
}
